<template>
    <div>
        <section class="relative leading-7 text-gray-900 bg-white border-solid pb-8 pt-5">
            <div class="box-border mx-auto border-solid lg:pl-8 max-w-7xl">
                <div class="flex flex-col items-center leading-7 text-gray-900 border-0 border-gray-200 lg:flex-row">
                    <div
                        class="box-border flex flex-col justify-center w-full h-full p-8 text-gray-900 border-solid lg:w-1/2 md:p-16 lg:p-0 lg:pl-10 lg:pr-20">
                        <h2 class="m-0 text-3xl font-medium leading-tight tracking-tight text-left text-black sm:text-4xl md:text-5xl">
                            How to buy BNB</h2>
                        <p class="mt-2 text-xl text-left border-0 border-gray-200 sm:text-2xl">From <a
                            href="https://Binance.com" class="underline">Binance.com</a></p>
                        <div
                            class="grid gap-4 mt-8 leading-7 border-0 border-gray-200 sm:mt-10 sm:gap-6 lg:mt-12 lg:gap-8">
                            <div class="box-border flex items-start text-gray-900 border-solid">
                                <div
                                    class="flex items-center justify-center w-12 h-12 leading-7 bg-blue-600 border-0 border-gray-200 rounded-full">
                                    <p class="box-border m-0 text-xl text-white border-solid">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none"
                                             viewBox="0 0 24 24" stroke="currentColor">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                                  d="M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z"/>
                                        </svg>
                                    </p>
                                </div>
                                <div class="flex-1 ml-6 leading-7 border-0 border-gray-200">
                                    <h3 class="box-border m-0 text-lg font-semibold leading-tight tracking-tight text-black border-solid sm:text-xl md:text-2xl">
                                        Registering on Binance</h3>
                                    <p class="box-border mt-2 text-base leading-normal text-gray-900 border-solid">
                                        Registering on binance is easy and fast. It takes about 2 minutes to finish
                                        registration.</p>
                                </div>
                            </div>
                            <div class="box-border flex items-start text-gray-900 border-solid">
                                <div
                                    class="flex items-center justify-center w-12 h-12 leading-7 bg-blue-600 border-0 border-gray-200 rounded-full">
                                    <p class="box-border m-0 text-xl text-white border-solid">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none"
                                             viewBox="0 0 24 24" stroke="currentColor">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                                  d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
                                        </svg>
                                    </p>
                                </div>
                                <div class="flex-1 ml-6 leading-7 border-0 border-gray-200">
                                    <h3 class="box-border m-0 text-lg font-semibold leading-tight tracking-tight text-black border-solid sm:text-xl md:text-2xl">
                                        Buy BNB on Binance</h3>
                                    <p class="box-border mt-2 text-base leading-normal text-gray-900 border-solid">
                                        Buying BNB on binance is just like buying a new pair of shoes on a webshop. I
                                        takes about 30 seconds.</p>

                                </div>
                            </div>
                            <div class="box-border flex items-start text-gray-900 border-solid">
                                <div
                                    class="flex items-center justify-center w-12 h-12 leading-7 bg-blue-600 border-0 border-gray-200 rounded-full">
                                    <p class="box-border m-0 text-xl text-white border-solid">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none"
                                             viewBox="0 0 24 24" stroke="currentColor">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                                  d="M8 7h12m0 0l-4-4m4 4l-4 4m0 6H4m0 0l4 4m-4-4l4-4"/>
                                        </svg>
                                    </p>
                                </div>
                                <div class="flex-1 ml-6 leading-7 border-0 border-gray-200">
                                    <h3 class="box-border m-0 text-lg font-semibold leading-tight tracking-tight text-black border-solid sm:text-xl md:text-2xl">
                                        Sending BNB from Binance to Metamask</h3>
                                    <p class="box-border mt-2 text-base leading-normal text-gray-900 border-solid">
                                        Sending BNB from binance is fast, easy and secure. It takes up to 5 minutes for
                                        your BNB to show up in your metamask.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        class="w-full h-full overflow-hidden leading-7 text-gray-900 border-0 border-gray-200 lg:w-1/2 rounded-lg shadow-lg">
                        <img
                            src="https://images.unsplash.com/photo-1614787296891-d1b2b1aced36?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80"
                            class="object-cover w-full h-full">
                    </div>
                </div>
            </div>
        </section>

        <!-- Section 2 -->
        <middle-banner/>

        <!-- Section 3 -->
        <section class="w-full py-12 bg-white sm:py-20"
        ">
        <div class="flex flex-col items-center justify-center mx-auto xl:flex-row max-w-7xl">
            <div
                class="relative flex flex-col items-start justify-center w-full h-full max-w-2xl px-6 mx-auto xl:max-w-none xl:w-2/5">

                <h2 class="text-3xl font-normal leading-none text-gray-900 sm:w-4/5 sm:text-6xl text-3">Sites that we
                    recommend</h2>
                <div class="w-20 h-1 mt-3 ml-1 bg-indigo-600 rounded-full"></div>

                <svg
                    class="absolute top-0 right-0 hidden w-auto h-24 mt-20 text-indigo-600 fill-current sm:block xl:mr-5 xl:-mt-24"
                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 180 74">
                    <defs></defs>
                    <g fill-rule="nonzero">
                        <path
                            d="M43.13 71.58c.29-.025.58-.025.87 0 .3 0 .71-.14 1-.17.54 0 1.08-.19 1.62-.23l2.3-.17 4.65-.25 6.47-.26h.68c3-.15 6-.3 9-.53 1.68-.14 5.83-.49 6.21-.52.63 0 4.36-.53 5-.63.25 0 1.95-.35 3.35-.58-.09-1-.55-1.77-.62-2.71-4.33.66-8.7 1.18-13 1.51-5.34.4-10.71.66-16.08 1l-7 .39c-1.4 0-2.82-.31-4.17-.26a2 2 0 00-1 .31c-.3.21-.4.16-.5.3a1.18 1.18 0 00-.12.81c.06.84.66 2.11 1.34 1.99zM106.33 62.04l.5-.23c.31-.13.72-.33 1.16-.56.88-.46 1.94-1 2.59-1.38l.56-.31a31.87 31.87 0 002.81-1.74 48.77 48.77 0 0014-13.59 38.22 38.22 0 004.34-8.87 28.9 28.9 0 001.51-9.86 31.56 31.56 0 00-3.3-13.46 23.9 23.9 0 00-3.62-5.22 20.47 20.47 0 00-2.38-2.22c-.42-.34-.89-.63-1.33-.94a10.88 10.88 0 00-1.38-.85A18.21 18.21 0 00109.16.92c-.5.11-1 .22-1.45.33-.45.11-.88.31-1.3.47-.42.16-.86.3-1.27.49-.41.19-.8.41-1.21.61A27.71 27.71 0 0098.5 6.5l-1.51 1.42-1.59 1.84c-.51.66-1 1.36-1.44 2-.44.64-.75 1.28-1 1.78a22.66 22.66 0 00-2 5 23.44 23.44 0 00-.82 5.31 26.71 26.71 0 00.72 7c.22 1.16.65 2.26 1 3.38a33.94 33.94 0 001.41 3.21 36.93 36.93 0 008.44 10.95 47.5 47.5 0 005.77 4.43 35.5 35.5 0 0010.02 4.59 86.41 86.41 0 0010 2.09 84.59 84.59 0 0018.2.51c4.8-.31 9.33-.8 13.8-1.39 2.25-.3 4.49-.63 6.76-1l3.43-.59.83-.11a15.59 15.59 0 001.98-.25 46 46 0 014.66-.82c.69-.12 2.24-.87 2.34-1.35.06-.28-.19-.56-.15-.85.09-.65-1.16-1.47-2.06-1.25-.9.22-1.89.51-2.84.73-.95.22-2 .37-3 .62a9.82 9.82 0 00-1.16.38c-.19.06-.39.13-.58.18l-.29.08-.69.12c-3.55.62-7 1.3-10.81 1.68-5.54.53-11.42 1.31-17.15 1.37a73.61 73.61 0 01-18.84-1.81 41.54 41.54 0 01-16.81-8.06 37.32 37.32 0 01-7.9-8.78 27.1 27.1 0 01-4.12-10.8C91.25 17.6 98.76 6.5 108.89 3.76a15.83 15.83 0 0114.56 3.4 23.24 23.24 0 017.36 13.74 28.32 28.32 0 01.29 8 28.05 28.05 0 01-2.06 7.7 37.16 37.16 0 01-5 8.63 39.08 39.08 0 01-7 7l-.87.66-.14.11c-1.69 1.29-3.61 2.56-5.55 3.75a54.34 54.34 0 01-12 5.4c-.42 1-1 2.35-.6 3.17 1.18-.35 2.25-.69 3.52-1.19.8-.28 1.61-.63 2.44-1 .83-.37 1.66-.72 2.49-1.09z"></path>
                        <path
                            d="M46.93 71a8.72 8.72 0 011.16 0c.51 0 1.48.05 2 0l8.52-.5c8.84-.54 17.78-1 26.66-2.45 2.33-.38 4.67-.8 7-1.29a56.65 56.65 0 0010.45-3.26 85.2 85.2 0 009.11-4.57 48.44 48.44 0 0014-12c.86-1 1.57-2.14 2.33-3.2s1.34-2.12 1.89-3.23a35.91 35.91 0 002.81-7.11 31.08 31.08 0 00.4-12.78 6.21 6.21 0 01-1.89 2.64 25.44 25.44 0 01-1 9.32l-.09.26a21.31 21.31 0 01-.69 2 41.94 41.94 0 01-3.72 7.43 41.78 41.78 0 01-5.3 6.63 52.72 52.72 0 01-15.45 10.61 73.71 73.71 0 01-18.17 5.41 207.23 207.23 0 01-24.09 2.59l-15.92.87a4.07 4.07 0 01-.01 2.63zM21.1 71.79a1.43 1.43 0 01-.27-1.49 2.72 2.72 0 011.81-1.54c1-.14 2.13.44 3.2.44 1.47 0 2.94-.27 4.42-.39 1-.08 1.92 0 2.86-.15a17 17 0 012.57-.11 5.7 5.7 0 001.17 0 3 3 0 011.12-.16c1 .18 1.3 2.22.71 2.91-.45.53-1.56.36-2.18.36h-2.67c-2.13.13-4.28 0-6.41.1-.91 0-1.8.24-2.7.35-.9.11-1.7.15-2.56.2a1.31 1.31 0 01-1.07-.52zM7.5 71.7a3.09 3.09 0 010-1 1.26 1.26 0 01.4-.74 2.18 2.18 0 012.16-.49 9.2 9.2 0 002.87 0 9.22 9.22 0 013.1 0 2 2 0 011.17.72c.46.6.61 1.35-.14 1.8a5.18 5.18 0 01-2.91.44c-1.34-.13-2.75.53-4.15.76a2 2 0 01-1.34-.22A2.49 2.49 0 017.5 71.7zM.01 71.57c.082-.29.2-.569.35-.83a1.91 1.91 0 013.27-.25c.54.63.61 2.26-.16 2.72a4.27 4.27 0 01-1.32.44c-1.12.1-2.05-.23-2.14-2.08z"></path>
                    </g>
                </svg>

            </div>
            <div
                class="box-content relative flex items-center w-full h-auto max-w-2xl py-5 mx-auto overflow-hidden xl:w-3/5 rounded-xl">

                <!-- Slide Page 1 -->
                <div class="flex px-6 space-x-6 transition duration-500 ease-out transform xl:pl-6 h-94 relative">

                    <!-- story 1 -->
                    <div class="flex flex-col flex-shrink-0 w-1/2 overflow-hidden rounded-lg shadow-lg mx-auto">
                        <div class="flex-shrink-0">
                            <a href="#">
                                <img class="object-cover w-full h-32 sm:h-48"
                                     src="https://images.unsplash.com/photo-1614787296891-d1b2b1aced36?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80"
                                     alt="">
                            </a>
                        </div>
                        <div class="flex flex-col justify-between flex-1 p-6 bg-white">
                            <div class="flex-1">
                                <p class="inline-block py-1 pl-10 pr-4 mb-2 -ml-10 text-xs font-medium leading-5 text-white transform -translate-y-2 bg-indigo-600 rounded">
                                    <a href="#" class="hover:underline" rel="category">Trading platform</a>
                                </p>
                                <a href="#" class="block">
                                    <span
                                        class="mt-2 text-base font-semibold leading-tight leading-7 text-gray-900 sm:text-xl">Binance</span>
                                    <span class="block mt-3 text-xs leading-6 text-gray-500 sm:text-sm">Binance is the biggest Trading Platform where you can buy and trade more than 100+ tokens. </span>
                                </a>
                            </div>

                        </div>
                    </div>


                </div>
            </div>
        </div>
        </section>

        <!-- Section 4 -->
        <section class="py-20 bg-white">
            <div class="flex flex-col px-8 mx-auto space-y-12 max-w-7xl xl:px-12">
                <div class="relative">
                    <h2 class="w-full text-3xl font-bold text-center sm:text-4xl md:text-5xl">Registering and buying BNB
                        on Binance</h2>
                    <p class="w-full py-8 mx-auto -mt-2 text-lg text-center text-gray-700 intro sm:max-w-3xl"></p>
                </div>
                <div class="flex flex-col mb-8 animated fadeIn sm:flex-row">
                    <div class="flex items-center mb-8 sm:w-1/2 md:w-5/12 sm:order-last">
                        <img class="rounded-lg shadow-xl" src="https://i.imgur.com/sZKEm9a.png" alt="">
                    </div>
                    <div class="flex flex-col justify-center mt-5 mb-8 md:mt-0 sm:w-1/2 md:w-7/12 sm:pr-16">
                        <p class="mb-2 text-sm font-semibold leading-none text-left text-indigo-600 uppercase">
                            REGISTERING</p>
                        <h3 class="mt-2 text-2xl sm:text-left md:text-4xl">Register on Binance</h3>
                        <p class="mt-5 text-lg text-gray-700 text md:text-left">To register on binance click on <a
                            href="https://www.binance.com/en/register?ref=89496083" class="underline">this link</a>.
                            Follow the sign up steps on the website to finish
                            your registration. It is important that the information you type is correct, as you will
                            need to go through they KYC process (Know Your Customer). This process is to verify that you
                            indeed are you. We also highly suggest that you setup 2FA (Two Factor Authentication) on
                            your account to keep it as secure as possible.</p>
                    </div>
                </div>
                <div class="flex flex-col mb-8 animated fadeIn sm:flex-row">
                    <div class="flex items-center mb-8 sm:w-1/2 md:w-5/12">
                        <img class="rounded-lg shadow-xl" src="https://i.imgur.com/SMXMyYF.png" alt="">
                    </div>
                    <div class="flex flex-col justify-center mt-5 mb-8 md:mt-0 sm:w-1/2 md:w-7/12 sm:pl-16">
                        <p class="mb-2 text-sm font-semibold leading-none text-left text-indigo-600 uppercase">
                            BUYING</p>
                        <h3 class="mt-2 text-2xl sm:text-left md:text-4xl">How to buy BNB</h3>
                        <p class="mt-5 text-lg text-gray-700 text md:text-left">Binance has made it very easy to buy BNB
                            on their website. Head over to
                            <a href="https://www.binance.com/en/buy-sell-crypto" class="underline">this link</a> to
                            start your purchase.
                        </p>
                        <p class="mt-5 text-lg text-gray-700 text md:text-left">
                            Select BNB and type in the amount you wish to spend. Press continue and fill out your credit
                            card information. Once your purchase has been completed, the BNB will be available instantly
                            on
                            your Binance Account. You can check your wallet by clicking <a
                            href="https://binance.com/en/my/wallet/account/overview" class="underline">here</a>
                        </p>
                    </div>
                </div>
                <div class="flex flex-col mb-8 animated fadeIn sm:flex-row">
                    <div class="flex items-center mb-8 sm:w-1/2 md:w-5/12 sm:order-last">
                        <img class="rounded-lg shadow-xl" src="https://i.imgur.com/rJ7k8pH.png" alt="">
                    </div>
                    <div class="flex flex-col justify-center mt-5 mb-8 md:mt-0 sm:w-1/2 md:w-7/12 sm:pr-16">
                        <p class="mb-2 text-sm font-semibold leading-none text-left text-indigo-600 uppercase">
                            WITHDRAW</p>
                        <h3 class="mt-2 text-2xl sm:text-left md:text-4xl">How to withdraw your BNB</h3>
                        <p class="mt-5 text-lg text-gray-700 text md:text-left">In order to withdraw your BNB to another
                            wallet, like MetaMask, you want to go to "wallet->Overview->Fiat and Spot" - or you can just
                            click on <a href="https://www.binance.com/en/my/wallet/account/main" class="underline">this
                                link</a>. From there you want to find BNB on the list and click <a
                                href="https://www.binance.com/en/my/wallet/account/main/withdrawal/crypto/BNB"
                                class="underline">Withdraw</a>. Withdrawing BNB gives you 2 options. BEP2 and BEP20.
                            BEP2 is the ERC Network and BEP20 is the BSC Network. Since tokengen runs on the BSC
                            Network, you want to use a BEP20 address. Once you've located your BEP20 address simply
                            enter it in the Adress field(Binance will automatically detect if the address is a BEP20 or
                            BEP2 address). Enter the amount you want to withdraw(min 0.02) and click the Withdraw
                            button. There's a gas fee of 0.0005 BNB when withdrawing from binance.</p>
                    </div>
                </div>

            </div>
        </section>
    </div>
</template>

<script>
import MiddleBanner from "./MiddleBanner";

export default {
    name: "HowToBuyBnb",
    components: {MiddleBanner}
}
</script>

<style scoped>

</style>
